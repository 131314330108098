import logo from "./logo.jpeg";
import { ReactComponent as ReactLogo } from "../src/headerlogo.svg";
import React from "react";
import "./App.css";
// import { BiRefresh, BiStar } from "react-icons/bi";
import { MdResetTv } from "react-icons/md";

import QrReader from "react-qr-reader";
// import { scan } from "micromatch";
import { fetchData, checkData, fetchPoints } from "./useApi";
// import { Text } from "react";

// const value = Math.floor(Math.random() * 90000) + 10000000;

let urlElements = "";
// let store_list = "";
// let store_name = "";
// let qrName = "";
class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: false,
      mobile: "",
      name: "",
      errors: {},
      urlElements: "",
      checkin_store_name: "",
      store_list: "",
      result: "",
      scan: false,
      qrButton: false,
      points: false,
      pointstext: "",
      qrcodevalue: "",
      message: false,
      bodymsg: "",
      bodymsg_head: "",
      bodymsg_text1: "",
      bodymsg_text2: "",
      bodymsg_text: "",
      bodymsg_valid_to: "",
      submit: true,
      offerList: false,
      offerCard: "",
      offerItems: [],
    };
  }
  onInputchange = (e) => {

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClick = () => {
    if (this.state.mobile.length === 10) {
      this.setState({
        number: true,
        // qrButton: true,
        points: true,
        offerList: true,
      });

      // this.handleCheckin();
      this.handlePoints();
    }
  };

  handleFetch = () => {
    //console.log(this.state.qrcodevalue.split("?"))

    let qrName = this.state.qrcodevalue.split("?");
    if (qrName[1] != null) {
      let api_params = qrName[1].split("&");

      if (api_params[0] != null) {
        this.setState({}, async () => {
          let methodType = api_params[0];
          let qr_code_val = "";
          if (methodType === "deptcheckin") {
            this.setState({ offerList: true })
            qr_code_val =
              api_params[1] + "&" + api_params[2] + "&" + api_params[3];
          } else {
            this.setState({ offerList: false })
            qr_code_val = qrName[1];
          }
          let qr_code_val1 = qr_code_val.replace(/%20/g, " ");
          let qr_store_name = api_params[1].replace(/%20/g, " ");
          var oData = {
            method: "general", //api_params[0],
            body: {
              mobileno: this.state.mobile,
              name: this.state.name,
              qrcode: qr_code_val1,
              storename: qr_store_name, //"Rise Naperville",
            },
          };
          console.log(oData);
          let data = await fetchData({ body: oData, methodType })
          this.setState({
            ...data,
            scan: false,
            message: true,
            number: false,
            submit: false,
            name: false
          });
        });
      }
    }
  };

  handleCheckin = () => {
    urlElements = window.location.href.split("?");
    // store_list = urlElements[1].split('&')
    // store_name = store_list[1]
    let qr_code = urlElements[1];
    //store_name = store_name.replace(/%20/g, " ")
    //console.log(store_list)
    // this.setState({
    //   checkin_store_name: "Rise Mundelein"
    // })
    //let storeName = qrName[3].split("&")
    if (qr_code == null) {
      // alert("Error while processing your request , Please try it again!");
      return null;
    }
    this.setState(
      {
        //checkin_store_name: store_name
      },
      async () => {
        var oData = {
          method: "general",
          body: {
            mobileno: this.state.mobile,
            fullname: this.state.name,
            //randomno: value,
            qrcode: qr_code,
            //"Rise Naperville",
          },
        };
        console.log(oData);

        let data = await checkData({ body: oData, qr_code })
        this.setState({
          ...data,
          scan: false,
          message: true,
          number: false,
          name: false,
          submit: false,
        });
      }
    );
  };
  handlePoints = () => {
    this.setState({
    },
      async () => {
        var oData = {
          method: "loyalty_query",
          body: {
            mobileno: this.state.mobile,
          },
        };
        console.log(oData);

        let data = await fetchPoints({ body: oData })
        console.log(data)
        this.setState({
          ...data,
          scan: false,
          message: true,
          points: true,
          pointstext: data.value,
          number: false,
          name: false,
          submit: false,
        });
      })
  }

  handleScan = (data) => {
    if (data) {
      //console.log(data)
      this.setState(
        {
          qrcodevalue: data,
          message: false,
          bodymsg_head: "dummy",
          bodymsg_text: "",
          bodymsg_text1: "",
          bodymsg_text2: "",
          bodymsg_valid_to: "",
        },
        () => {
          this.handleFetch();
          this.setState({ scan: false });
        }
      );
    }
  };
  // handleScan = () => {
  //   this.setState({ scan: true });
  // };
  handleError = (err) => {
    console.error(err);
  };
  handleReload() {
    window.location.reload(false)
  }
  formValidation = () => {
    const { mobile } = this.state;
    let isValid = true;
    const errors = {};
    if (mobile.trim().length <= 0) {
      errors.mobile = "Enter Mobile Number"
      isValid = false;
    }
    // else if (mobile.trim().length < 10) {
    //   errors.mobile = "Mobile Number must be in 10 digits";
    //   isValid = false;
    // }
    this.setState({ errors });
    return isValid;
  }
  onSubmit = (e) => {
    e.preventDefault();
    //const isValid = this.formValidation();
  }
  render() {
    const { errors } = this.state;
    return (
      <div className="App">
        <header style={{ backgroundColor: "#045624", height: "100px" }}>
          <ReactLogo style={{ height: "120px" }} />
        </header>
        <div className="App-header">
          <img
            src={logo}
            className="App-logo"
            alt="logo"
            style={{ height: "80px", paddingBottom: '20px' }}
          />
          {/* {console.log(this.state.pointstext.split("-")[1])} */}

          {
            this.state.points === true ? (
              <div>
                {/* <h2 style={{ color: '#045625', fontFamily: 'beatrice,Arial,sans-serif' }}>
                  {this.state.pointstext.split("-")[0]}
                </h2>
                <h3 style={{ color: '#045625', fontFamily: 'beatrice,Arial,sans-serif', width: "40%", margin: '0 30%' }}>
                  {this.state.pointstext.split("-")[1]}
                </h3> */}
                <h3 style={{ color: '#045625', fontFamily: 'beatrice,Arial,sans-serif', width: "40%", margin: '0 30%' }}>
                  {/* {this.state.pointstext.body.replace(/['"]+/g, "")} */}
                  {this.state.pointstext.body}
                </h3>
                <button className="goback" onClick={this.handleReload}>
                  Go Back
                </button >
              </div>
            ) : ""
          }
                
          {this.state.qrButton === true ? (
            <button
              className="button button1"
              onClick={() => {
                this.setState({
                  scan: true,
                  bodymsg_head: "",
                  bodymsg_text: "",
                  bodymsg_text1: "",
                  bodymsg_text2: "",
                  bodymsg_valid_to: "",
                });
                this.handleScan();
              }}
            >
              Scan QR
            </button>
          ) : (
            ""
          )}
          {this.state.scan === true ? (
            <>
              {" "}
              <QrReader
                delay={300}
                onError={this.handleError}
                onScan={this.handleScan}
                style={{ width: "100%" }}
              />
              <p style={{ color: "green" }}>{this.state.result}</p>
            </>
          ) : (
            ""
          )}
          {this.state.submit === true ? (
            <form onSubmit={this.onSubmit}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  type="number"
                  name='mobile'
                  onChange={this.onInputchange}
                  value={this.state.mobile}
                  style={{ marginTop: "30px" }}
                  placeholder="Enter Mobile No"
                ></input>
                {
                  Object.keys(errors).map((key) => {
                    return <div style={{ color: 'red', fontSize: '14px' }} key={key}>{errors[key]}</div>
                  })
                }
              </div>
              <div style={{ marginTop: "10px" }}>
                <button className="button button1" onClick={this.handleClick} type="submit">
                  Submit
                </button>
                <button
                  className="button button2"
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    this.setState({
                      number: false,
                      mobile: "",
                      scan: false,
                      qrButton: false,
                      message: false,
                      bodymsg_head: "",
                      bodymsg_text: "",
                      bodymsg_text1: "",
                      bodymsg_text2: "",
                      bodymsg_valid_to: "",
                    })
                  }
                >
                  <MdResetTv />
                </button>
              </div>
            </form>
          ) : (
            ""
          )}
          {this.state.offerList === true ? (
            <>
              {this.state.offerItems.map((e) => (
                <div
                  style={{
                    display: "flex",
                    border: "1px solid grey",
                    marginTop: "50px",
                    borderRadius: "30px",
                    padding: "10px",
                    width: "80vw",
                    height: "30vw"
                  }}
                >
                  <div>
                    <img
                      src={e.imgsrc}
                      className="App-logo"
                      alt="logo"
                      style={{
                        height: "60px",
                        marginTop: "20px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      color: "black",
                      fontSize: "20px",
                      padding: "15px",
                    }}
                  >
                    <div
                      style={{
                        color: "grey",
                        fontSize: "20px",
                        marginTop: "2px",
                      }}
                    >
                      {e.title}
                    </div>



                    <div
                      style={{
                        color: "grey",
                        fontSize: "15px",
                        marginTop: "18px",
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #07562C",
                          marginRight: "10px",
                          borderRadius: "5px",
                          fontSize: "12px",
                          padding: "3px",
                          background: "#07562C",
                          color: "white",
                          width: "30px"
                        }}
                      >
                        {e.cat1}
                      </span>
                      <span
                        style={{
                          border: "1px solid #710e68",
                          marginRight: "10px",
                          borderRadius: "5px",
                          fontSize: "12px",
                          padding: "3px",
                          background: "#710e68",
                          color: "white",
                        }}
                      >
                        {e.cat2 !== "null" ? e.cat2 : "null"}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            ""
          )}
        </div>

        <footer style={{
          position: "absolute",
          left: 0,
          bottom: 0,
          right: 0
        }}>
          <div class="copyright-large" style={{ color: "white", backgroundColor: "#045624", fontSize: "15px" }}>
            © 2021 Green Thumb Industries (GTI).<br></br>All Rights
            Reserved.
          </div>
        </footer>
      </div >
    );
  }
}
export default Landing;
