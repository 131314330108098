import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Landing from './Landing';
import Loyalty from './Loyalty';
// import 
//!testing
export default function App() {
  return (
    <Router>
      <Switch>
        {/* <Route path="loyalty" element={<Loyalty />} />
        <Route path="/" element={<Landing />} /> */}
        <Route path="/loyalty">
          <Loyalty />
        </Route>
        <Route path="/">
          <Landing />
        </Route>
      </Switch>
    </Router>
  )
}
